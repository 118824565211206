var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col m-auto"},[_c('a-button',{staticClass:"m-2",staticStyle:{"width":"98%"},attrs:{"icon":"book"},on:{"click":function($event){return _vm.showModal(1)}}},[_vm._v(" "+_vm._s(_vm.$t("actualite.ajouterActualite"))+" ")])],1),_c('div',{staticClass:"col m-auto"},[_c('a-button',{staticClass:"m-2",staticStyle:{"width":"98%"},attrs:{"icon":"camera"},on:{"click":function($event){return _vm.showModal(2)}}},[_vm._v(" "+_vm._s(_vm.$t("actualite.ajouterAlbum"))+" ")])],1)]),_c('a-modal',{attrs:{"visible":_vm.previewVisible,"footer":null},on:{"cancel":function($event){_vm.previewVisible = false}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"alt":"example","src":_vm.previewImage}})]),_c('a-modal',{attrs:{"title":_vm.isAlbum
        ? _vm.$t('actualite.ajouterAlbum')
        : _vm.$t('actualite.ajouterActualite'),"width":_vm.isAlbum ? 800 : 1000,"height":1200,"footer":false},on:{"cancel":_vm.handleCancel},model:{value:(_vm.visibleModal),callback:function ($$v) {_vm.visibleModal=$$v},expression:"visibleModal"}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 3 },"wrapper-col":{ span: 20 }},on:{"submit":_vm.addNews}},[_c('a-form-item',{attrs:{"label":_vm.$t('actualite.titre')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'title',
            {
              rules: [
                {
                  required: true,
                  message: _vm.$t('requis.titre'),
                } ],
            } ]),expression:"[\n            'title',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: $t('requis.titre'),\n                },\n              ],\n            },\n          ]"}],attrs:{"placeholder":_vm.$t('actualite.titre')}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('actualite.date')}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'plannifDate',
            {
              initialValue: _vm.moment(), // Set initial value to current date using moment.js
              rules: [
                {
                  required: true,
                  message: _vm.$t('requis.date'),
                } ],
            } ]),expression:"[\n            'plannifDate',\n            {\n              initialValue: moment(), // Set initial value to current date using moment.js\n              rules: [\n                {\n                  required: true,\n                  message: $t('requis.date'),\n                },\n              ],\n            },\n          ]"}],staticClass:"full",attrs:{"placeholder":_vm.$t('actualite.date'),"show-time":"","format":"YYYY-MM-DD HH:mm:ss"}})],1),(!_vm.isAlbum)?_c('a-form-item',{attrs:{"label":_vm.$t('actualite.categorie')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'category',
            {
              rules: [
                {
                  required: true,
                  message: _vm.$t('requis.categorie'),
                } ],
            } ]),expression:"[\n            'category',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: $t('requis.categorie'),\n                },\n              ],\n            },\n          ]"}],attrs:{"type":"string"}})],1):_vm._e(),_c('a-form-item',{attrs:{"label":_vm.$t('actualite.cibles')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'target',
            {
              rules: [
                {
                  required: true,
                  message: _vm.$t('requis.cible'),
                } ],
            } ]),expression:"[\n            'target',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: $t('requis.cible'),\n                },\n              ],\n            },\n          ]"}],attrs:{"mode":"multiple"},on:{"change":function (val) {
              if (!val.includes('parents')) { _vm.selectedStudents = []; }
            }}},[_c('a-select-option',{attrs:{"value":"all"}},[_vm._v(" "+_vm._s(_vm.$t("actualite.tous"))+" ")]),_c('a-select-option',{attrs:{"value":"teacher"}},[_vm._v(" "+_vm._s(_vm.$t("actualite.enseignants"))+" ")]),_c('a-select-option',{attrs:{"value":"students"}},[_vm._v(" "+_vm._s(_vm.$t("actualite.eleves"))+" ")]),_c('a-select-option',{attrs:{"value":"administration"}},[_vm._v(" "+_vm._s(_vm.$t("actualite.administration"))+" ")]),_c('a-select-option',{attrs:{"value":"parents"}},[_vm._v(" "+_vm._s(_vm.$t("actualite.parents"))+" ")])],1)],1),(
          _vm.form.getFieldValue('target') &&
          _vm.form.getFieldValue('target').includes('parents')
        )?_c('div',{staticStyle:{"text-align":"center"}},[_c('a-tag',{staticClass:"mb-4",attrs:{"color":"blue"}},[_vm._v(_vm._s(_vm.$t("actualite.specifiedInfo")))]),_c('a-form-item',{attrs:{"label":_vm.$t('actualite.specificFor')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'specificFor',
              {
                initialValue: 'all',
                rules: [{ required: true }],
              } ]),expression:"[\n              'specificFor',\n              {\n                initialValue: 'all',\n                rules: [{ required: true }],\n              },\n            ]"}],attrs:{"default-value":"all"},on:{"change":function () {
                _vm.selectedStudents = [];
              }}},[_c('a-select-option',{attrs:{"value":"all"},on:{"change":function () {
                  _vm.selectedStudents = [];
                }}},[_vm._v(" "+_vm._s(_vm.$t("actualite.tous"))+" ")]),_c('a-select-option',{attrs:{"value":"students"}},[_vm._v(" "+_vm._s(_vm.$t("actualite.students"))+" ")]),_c('a-select-option',{attrs:{"value":"levels"}},[_vm._v(" "+_vm._s(_vm.$t("actualite.levels"))+" ")]),_c('a-select-option',{attrs:{"value":"classrooms"}},[_vm._v(" "+_vm._s(_vm.$t("actualite.classrooms"))+" ")])],1)],1),(
            _vm.form.getFieldValue('specificFor') &&
            _vm.form.getFieldValue('specificFor') == 'levels'
          )?_c('a-form-item',{attrs:{"label":_vm.$t('actualite.levels')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'levels',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.$t('enseignant.niveauxRequis'),
                  } ],
              } ]),expression:"[\n              'levels',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: $t('enseignant.niveauxRequis'),\n                  },\n                ],\n              },\n            ]"}],attrs:{"placeholder":_vm.$t('enseignant.listeNiveaux'),"mode":"multiple"}},[_c('a-select-option',{attrs:{"value":"1"}},[_vm._v(" "+_vm._s(_vm.$t("niveau.niveau1"))+" ")]),_c('a-select-option',{attrs:{"value":"2"}},[_vm._v(" "+_vm._s(_vm.$t("niveau.niveau2"))+" ")]),_c('a-select-option',{attrs:{"value":"3"}},[_vm._v(" "+_vm._s(_vm.$t("niveau.niveau3"))+" ")]),_c('a-select-option',{attrs:{"value":"4"}},[_vm._v(" "+_vm._s(_vm.$t("niveau.niveau4"))+" ")]),_c('a-select-option',{attrs:{"value":"5"}},[_vm._v(" "+_vm._s(_vm.$t("niveau.niveau5"))+" ")]),_c('a-select-option',{attrs:{"value":"6"}},[_vm._v(" "+_vm._s(_vm.$t("niveau.niveau6"))+" ")]),_c('a-select-option',{attrs:{"value":"7"}},[_vm._v(" "+_vm._s(_vm.$t("niveau.niveau7"))+" ")]),_c('a-select-option',{attrs:{"value":"8"}},[_vm._v(" "+_vm._s(_vm.$t("niveau.niveau8"))+" ")]),_c('a-select-option',{attrs:{"value":"9"}},[_vm._v(" "+_vm._s(_vm.$t("niveau.niveau9"))+" ")]),_c('a-select-option',{attrs:{"value":"10"}},[_vm._v(" "+_vm._s(_vm.$t("niveau.niveau10"))+" ")]),_c('a-select-option',{attrs:{"value":"11"}},[_vm._v(" "+_vm._s(_vm.$t("niveau.niveau11"))+" ")]),_c('a-select-option',{attrs:{"value":"12"}},[_vm._v(" "+_vm._s(_vm.$t("niveau.niveau12"))+" ")]),_c('a-select-option',{attrs:{"value":"13"}},[_vm._v(" "+_vm._s(_vm.$t("niveau.niveau13"))+" ")])],1)],1):_vm._e(),(
            _vm.form.getFieldValue('specificFor') &&
            _vm.form.getFieldValue('specificFor') == 'classrooms'
          )?_c('a-form-item',{attrs:{"label":_vm.$t('actualite.classrooms')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'classrooms',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.$t('choisir.classe'),
                  } ],
              } ]),expression:"[\n              'classrooms',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: $t('choisir.classe'),\n                  },\n                ],\n              },\n            ]"}],attrs:{"placeholder":_vm.$t('actualite.classrooms'),"mode":"multiple"}},_vm._l((_vm.classes),function(classe){return _c('a-select-option',{key:classe._id,attrs:{"value":classe._id}},[_vm._v(" "+_vm._s(classe.name)+" ")])}),1)],1):_vm._e(),(
            _vm.form.getFieldValue('specificFor') &&
            _vm.form.getFieldValue('specificFor') == 'students'
          )?_c('a-form-item',{attrs:{"label":_vm.$t('actualite.students')}},[_c('multiselect',{attrs:{"placeholder":_vm.$t('sms.selectionnerEleves'),"selectLabel":_vm.$t('sms.pressEnterToSelect'),"options":_vm.studentList,"custom-label":_vm.customLabel,"close-on-select":false,"preserve-search":true,"maxHeight":700,"label":"_id","track-by":"_id","preselect-first":false,"multiple":true},scopedSlots:_vm._u([{key:"option",fn:function(props){return [_c('div',{staticClass:"option__desc"},[_c('span',{staticClass:"align-text-top"},[_vm._v(" "+_vm._s(props.option.lastName + " " + props.option.firstName)+" ")]),_c('br'),_c('span',{staticClass:"option__small font-size-12 text-warning"},[_vm._v("Parent: "+_vm._s(props.option.parent.father.firstName)+" "+_vm._s(props.option.parent.father.lastName)+" et "+_vm._s(props.option.parent.mother.firstName)+" "+_vm._s(props.option.parent.mother.lastName))])])]}}],null,false,3628029544),model:{value:(_vm.selectedStudents),callback:function ($$v) {_vm.selectedStudents=$$v},expression:"selectedStudents"}})],1):_vm._e()],1):_vm._e(),(!_vm.isAlbum)?_c('a-form-item',{attrs:{"label":_vm.$t('actualite.description')}},[_c('quill-editor',{attrs:{"options":_vm.editorOptions},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1):_vm._e(),_c('a-form-item',{attrs:{"label":_vm.$t('actualite.photos')}},[(!_vm.isAlbum)?_c('a-upload-dragger',{attrs:{"file-list":_vm.photos,"before-upload":_vm.beforeUploadFiles,"multiple":"","custom-request":function () {
              return true;
            }}},[_c('p',{staticClass:"ant-upload-drag-icon"},[_c('inbox-outlined')],1),_c('p',{staticClass:"ant-upload-text"},[_vm._v(_vm._s(_vm.$t("actualite.cliquer")))]),_c('p',{staticClass:"ant-upload-hint"},[_vm._v(" "+_vm._s(_vm.$t("actualite.support"))+" ")])]):_c('div',{staticClass:"clearfix"},[_c('a-upload',{attrs:{"list-type":"picture-card","file-list":_vm.photos,"before-upload":_vm.beforeUploadFiles,"multiple":"","custom-request":function () {
                return true;
              }},on:{"preview":_vm.handlePreview,"change":_vm.handleChange}},[_c('div',[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("Upload")])],1)])],1)],1),_c('a-progress',{directives:[{name:"show",rawName:"v-show",value:(_vm.uploadPercentage > 0),expression:"uploadPercentage > 0"}],attrs:{"stroke-color":{
          from: '#108ee9',
          to: '#87d068',
        },"percent":_vm.uploadPercentage,"status":"active"}}),_c('div',{staticClass:"form-actions mt-0"},[_c('a-button',{staticClass:"mr-3",staticStyle:{"width":"150px"},attrs:{"type":"primary","htmlType":"submit","loading":_vm.loading,"disabled":_vm.loading}},[_vm._v(" "+_vm._s(_vm.$t("action.ajouter"))+" ")]),_c('a-button',{key:"back",attrs:{"disabled":_vm.loading},on:{"click":_vm.handleCancel}},[_vm._v(" "+_vm._s(_vm.$t("action.annuler"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }